.meetingstats
{
    position: relative;
    color: var(--default-color);
    margin-top: 10px;

    width: 300px;
    flex: 1 1 auto;

    &__title
    {
        display: flex;
        text-align: center;
        border: solid 1px var(--default-border);

        &__element
        {
            flex: 1 1 33%;

            &__select
            {
                padding: 0;
                width: 100%;

                font-size: 60% !important;
                height: 18px !important;

                .ant-select-selector
                {
                    padding: 0 ! important;
                    background-color: var(--color-button-back) !important;
                    color: white !important;
                    border-color: var(--color-button-back) !important;
                }
            }
        }
    }

    &__table
    {
        display: table;
        width: 100%;
        border-right: solid 1px var(--default-border);
        border-left: solid 1px var(--default-border);
        border-bottom: solid 1px var(--default-border);
        font-size: 80%;
        border-spacing: 0;

        &__row
        {
            &__top td
            {
              border-top: solid 1px var(--default-border);
            }
        }

        &__item
        {
            text-align: center;
            border-right: solid 1px var(--default-border);
            font-size: 80%;

            &:last-child
            {
                border-right: 0;
            }
            &__left
            {
              text-align: left;
            }
        }
    }
}