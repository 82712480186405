.visiograph
{
    &__comp
    {
        width: 100%;
        margin-right: 8px;
        font-size: 80%;

        &__title
        {
            font-size: 90%;
            width: 100%;
            text-align: center;
            border: solid 1px var(--default-border);
        }
        &__table
        {
            width: 100%;
            border-right: solid 1px var(--default-border);
            border-left: solid 1px var(--default-border);
            border-bottom: solid 1px var(--default-border);
            font-size: 80%;
        }
        &__row
        {
            text-align: center;
            border-right: solid 0px var(--default-border);
            display: flex;
            flex-direction: row;
        }
        &__number
        {
            flex: 0 0 60px;
            text-align: center;
        }
        &__graph
        {
            border-right: solid 0px var(--default-border);
            flex: 1 1 100%;
            border-bottom: solid 1px var(--default-border);
        }
    }
}
