.server
{
    width: 170px;
    text-align: center;
    margin: 5px;
    font-size: 90%;
    display: flex;
    flex-direction: column;

    &__title
    {
        position: relative;
        background-color: var(--default-title);
        margin: 0;
        &__name
        {
            white-space: nowrap;
            overflow-x: hidden;
            width: auto;
            max-width: 100%;
            display: inline;
            transform-origin: left;
        }
        &__ip
        {
            font-size: 50%;
            height: 11px;
        }
        &__linkhpv2
        {
            position: absolute;
            right: 0;
            bottom: -2px;
            font-size: 50%;
        }
        &__good
        {
            background-color: var(--state-good);
        }
    }
}