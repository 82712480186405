.groups
{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 1.4rem;

    &__group
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 4px;

        &__cont
        {
            display: flex;
            flex-direction: column;
        }
        &__title
        {
            text-align: center;
        }
        &__servers
        {
            display: flex;
            flex-direction: row;
        }
}
}