@import '../constants';

.visiographs
{
	position: relative;
	max-width: 1300px;
	margin: 0 auto;
	&__maincont
	{
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		margin-top: 60px;
		padding: 0 5px;;
	}
	&__day
	{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		flex: 1 1 auto;
		margin-bottom: 40px;

		&__title
		{
			text-align: center;
			width: 100%;
			background-color: #7e8188;
			display: flex;
			&__element
			{
				flex: 1 1 33%;

				&__button
				{
					background-color: var(--color-button-back);
					border-radius: 5px;
					border: 1px solid var(--default-border);
					padding: 2px 5px;
					cursor: pointer;
					width: 40px;
					font-size: 60%;
					margin: 1px 4px 0 4px;
					&:hover
					{
						background-color: var(--color-button-hover);
					}
				}
			}
		}
		&__meetings
		{
			display: flex;
			flex-wrap: wrap;

			&__meeting
			{
				margin-right: 10px;
			}
		}
	}

	&__search
	{
		display: flex;
		flex-wrap: wrap;
		font-size: 90%;
		margin-bottom: 20px;

		&__couple
		{
			margin: 0px 10px 5px 0;
			width: 160px;

			& > .ant-btn
			{
				background-color: var(--color-button-back);
				border: 1px solid var(--default-border);
				color: white;
			}
			& > .ant-btn:hover
			{
				background-color: var(--color-button-hover) !important;
				color: white !important;
				border-color: white !important;
			}
		}
		&__label
		{
			height: 17px;
			margin-bottom: 2px;
		}
		&__select
		{
			width: 100%;
		}
	}
	&__stats
	{
		display: flex;
		flex-wrap: wrap;
		font-size: 90%;
		margin-bottom: 20px;

		&__couple
		{
			display: flex;
			margin-right: 15px;
		}
		&__value
		{
			margin-left: 5px;
		}
	}
}
