@import '../../constants';

.program
{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 1.4rem;
    margin-left: 25px;
    margin-right: 25px;
    width: 350px;
    white-space: nowrap;

    @media screen and (max-width: $responsive-width)
    {
        margin: 0 auto;
        margin-top: 40px; 
        width: auto;
    }

    &__cont
    {
        border: 1px solid var(--default-border);
        overflow-x: hidden;
        @media screen and (max-width: $responsive-width)
        {
            margin-left: 30px;
            margin-right: 30px;
            border: 0;
        }
    }

    &__title
    {
        text-align: center;
        margin-bottom: 10px;
        width: 100%;
        background-color: var(--default-title);
        padding-bottom: 5px;

        @media screen and (max-width: $responsive-width)
        {
            padding-top: 3px;
            padding-bottom: 2px;
        }
    }
    &__meetings
    {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $responsive-width)
        {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__meeting
    {
        display: flex;
        flex-direction: column;
        font-size: 80%;
        padding-left: 5px;
        padding-right: 5px;
        overflow-x: hidden;

        @media screen and (max-width: $responsive-width)
        {
            width: 300px;
            border: 1px solid var(--default-border);
        }

        &__name
        {
            display: flex;
            flex-direction: row;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        &__races
        {
            display: flex;
            flex-direction: column;
            font-size: 70%;
            padding-left: 5px;

            &__race
            {
                display: flex;
                flex-direction: column;
                position: relative;

                &__mecstates
                {
                    position: absolute;
                    top: 0;
                    right: 0;

                    display: flex;
                }
                &__led
                {
                    margin: 0 2px;
                    width: 14px;
                    height: 14px;
                    border-radius: 5px;
                }
            }
            &__line1
            {
                display: flex;
                flex-direction: row;
            }
            &__line2
            {
                display: flex;
                flex-direction: row;
                font-size: 80%;
            }
            &__number
            {
                flex: 0 0 28px;
            }
            &__time
            {
                flex: 0 0 35px;
                margin-right: 5px;
            }
        }
    }
}