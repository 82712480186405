@import '../constants';

.home
{
	position: relative;
	&__button
	{
		position: absolute;
		right: 45px;
		top: 5px;
		background-color: var(--color-button-back);
		border: 1px solid var(--default-border);
		border-radius: 5px;
		padding: 2px 5px;
		cursor: pointer;
		&:hover
		{
			background-color: var(--color-button-hover);
		}
	}
	&__maincont
	{
		display: flex;
		flex-direction: row;
		font-size: 80%;
		flex: 1 1 auto;

		&__central
		{
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
		}
		&__program
		{
			flex: 0 0 auto;
			@media screen and (max-width: $responsive-width)
			{
				flex: 1 1 auto;
				width: 100%;
				margin: 0 auto;
			}
		}

		@media screen and (max-width: $responsive-width)
		{
			flex-direction: column-reverse;
		}
	}
}