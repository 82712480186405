@import './constants';

:root {
	font-family: 'Lato', Inter, system-ui, Helvetica, Arial, sans-serif;
	background-color: var(--default-background);
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  color: white;
}
