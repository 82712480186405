.visiocompetitor
{
    width: 100%;
    height: 14px;

    canvas
    {
        width: 100%;
        height: 100%;
    }
}
