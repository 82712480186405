.meetinggraphs
{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &__graph
    {
        margin-bottom: 7px;
    }
}
