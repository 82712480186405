@import '../../constants';

.racetexts
{
    position: relative;
    &__cont
    {
        overflow: hidden;
        height: 15.45px;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__line1
    {
        flex-direction: row;
    }
    &__line2
    {
        flex-direction: row;
    }
    &__effect
    {
        transform: translateY(-15.45px);
        transition: transform 2000ms;
    }    
}