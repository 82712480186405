.raceinfos
{
    font-size: 90%;
    &__line
    {
        background-color: var(--color-gold);
        display: flex;
    }
    &__case
    {
        display: flex;
        flex-direction: column;
        padding: 0 2px;
        text-align: center;
        min-width: 30px
    }
    &__gauge
    {
        margin-left: 5px;
        &__title
        {
            text-align: center;
            font-size: 70%;
            margin-top: 2px;
            vertical-align: bottom;
        }
    }
    &__chrono
    {
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        &__line
        {
            position: relative;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--default-border);
            border-right: 1px solid var(--default-border);

            &__number
            {
                width: 16px;
                text-align: center;
                border-right: 1px solid var(--default-border);
                border-left: 1px solid var(--default-border);
                // background-color: var(--color-black);
            }

            &__qmods
            {
                width: 100%;
                display: flex;
                flex-direction: row;
            }
            &__qmod
            {
                width: calc(100% / 10);
                border-right: 1px solid var(--default-border);
            }
            &__qcolor0
            {
                background-color: var(--color-black);
            }
            &__qcolor1
            {
                background-color: #df4d09;
            }
            &__qcolor2
            {
                background-color: #c56b05;
            }
            &__qcolor3
            {
                background-color: #c8d600;
            }
            &__qcolor4
            {
                background-color: #0e4d0c;
            }
            &__qcolor5
            {
                background-color: #25b320;
            }
            &__latency
            {
                position: absolute;
                top: 1px;
                right: 2px;
                font-size: 70%;
            }
        }
    }
}