.gauge
{
    display: flex;

    &__cont
    {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 40px;
    }

    &__title
    {
        margin-top: 1px;
        font-size: 60%;
        background-color: transparent;
        z-index: 1;
    }
    &__value
    {
        margin-top: auto;
        margin-bottom: auto;
        background-color: transparent;
        z-index: 1;
        padding-bottom: 5px;
    }
    &__free
    {
        position: absolute;
        background-color: transparent;
        z-index: 1;
        font-size: 60%;
        white-space: nowrap;
        bottom: 0;
        width: 100%;
    }
    &__gauge
    {
        position: absolute;
        background-color: darkgreen;
        top: 100%;
        bottom: 0;
        width: 100%;
        z-index: 0;
    }
}