.infostats
{
    position: relative;
    color: var(--default-color);
    margin-top: 10px;

    max-width: 300px;
    flex: 1 1 auto;

    &__title
    {
        text-align: center;
        border: solid 1px var(--default-border);
    }

    &__table
    {
        display: table;
        width: 100%;
        border-right: solid 1px var(--default-border);
        border-left: solid 1px var(--default-border);
        border-bottom: solid 1px var(--default-border);
        font-size: 80%;
        border-spacing: 0;

        &__row
        {
            &__top td
            {
              border-top: solid 1px var(--default-border);
            }
        }

        &__item
        {
            text-align: center;
            border-right: solid 1px var(--default-border);
            font-size: 80%;

            &:last-child
            {
                border-right: 0;
            }
            &__left
            {
              text-align: left;
            }
        }
    }
}