.gaugedistance
{
    &__cont
    {
        display: block;
        position: relative;
        width: 60px;
        border: 1px solid white;
        height: 14px;
    }

    &__gauge
    {
        display: block;
        position: absolute;
        background-color: darkgreen;
        top: 0;
        left: 0;
        height: 100%;
    }
    &__value
    {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 100%;
        text-align: center;
    }
}