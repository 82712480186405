:root {
  --default-background: #282c34;
  --default-title: #181a1f;
  --default-border: #181a1f;
  --default-color: white;

  --state-good: darkgreen;
  --state-alert: #AF7500;
  --state-alarm: darkred;
  --state-neutral: #00000000;

  --color-gold: #a78349;
  --color-black: #000000;
  --color-white: white;

  --color-button-back: #a78349;
  --color-button-hover: #e0af60;

}
$responsive-width: 1300px;
