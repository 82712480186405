.detailstats
{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__comp
    {
        width: 300px;
        margin-right: 8px;
        font-size: 80%;

        & table
        {
            width: 100%;
            border-right: solid 1px var(--default-border);
            border-left: solid 1px var(--default-border);
            border-bottom: solid 1px var(--default-border);
            font-size: 80%;
            border-spacing: 0;
        }
        & td
        {
            text-align: center;
            border-right: solid 1px var(--default-border);

            &:last-child
            {
                border-right: 0;
            }
        }
        &__header td
        {
            border-bottom: solid 1px var(--default-border);
        }
        &__title
        {
            font-size: 90%;
            width: 100%;
            text-align: center;
            border: solid 1px var(--default-border);
        }
    }
}
