.states
{
    display: flex;
    flex-direction: column;
    font-size: 70%;
    background-color: var(--default-background);

    &__global
    {
        display: block;
        text-align: center;
        font-size: 80%;
        border: 1px solid var(--default-border) ;
        padding-bottom: 1px;
    }
    &__gauges
    {
        display: flex;

        &__cont
        {
            flex: 1;
            border-left: 1px solid var(--default-border) ;
        }
        &__cont:last-child
        {
            border-right: 1px solid var(--default-border);
        }
        border-bottom: 1px solid var(--default-border) ;
    }
    &__others
    {
        display: flex;
        flex-direction: column;           
        font-size: 80%;

        &__cont
        {
            border-left: 1px solid var(--default-border);
            border-right: 1px solid var(--default-border);
            border-bottom: 1px solid var(--default-border);

            padding-bottom: 1px;
        }
        &__line
        {
            display: flex;
            padding-bottom: 1px;
            white-space: nowrap;
            &__text
            {
                width: calc(100% - 32px);
                overflow-x: hidden;
                z-index: 10;
                font-size: 80%;
            }
            a
            {
                color: white;
                text-align: left;
                padding-left: 1px;
            }
            &__led
            {
                position: absolute;
                right: 2px;
                width: 30px;                
                height: 10px;
                margin-top: 1px;
                font-size: 90%;
                z-index: 28;
            }
        }
    }
    &__lives
    {
        display: flex;
        flex-direction: column;           
        font-size: 80%;

        &__cont
        {
            position: relative;
            border-left: 1px solid var(--default-border);
            border-right: 1px solid var(--default-border);
            border-bottom: 1px solid var(--default-border);

            &__cont
            {
                position: relative;
            }
            &__signal
            {
                // border: 5px solid rgb(255, 145, 0);
                background-color: var(--color-gold);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
        &__title
        {
            width: 100%;
            background-color: var(--default-border);
        }
        &__line
        {
            display: flex;
            padding-bottom: 1px;
            &__text
            {
                width: calc(100% - 32px);
                overflow-x: hidden;
                z-index: 10;
                font-size: 80%;
            }
            &__led
            {
                position: absolute;
                right: 2px;
                width: 30px;                
                z-index: 10;
                height: 10px;
                margin-top: 1px;
                z-index: 28;
            }
        }
    }
}