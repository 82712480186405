@import '../constants';

.racingraces
{
	position: relative;
	max-width: 1300px;
	margin: 0 auto;
	&__maincont
	{
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		margin-top: 60px;
	}
	&__day
	{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		flex: 1 1 auto;
		margin-bottom: 40px;

		&__title
		{
			text-align: center;
			width: 100%;
			background-color: var(--color-gold);
		}
		&__meetings
		{
			display: flex;

			&__meeting
			{
				margin-right: 10px;
			}
		}

		&__details
		{
			&__meeting
			{
				margin-top: 15px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				&__comp
				{
					width: 150px;
					margin-right: 8px;
					font-size: 80%;

					& table
					{
						width: 100%;
						border-right: solid 1px var(--default-border);
						border-left: solid 1px var(--default-border);
						border-bottom: solid 1px var(--default-border);
						font-size: 80%;
						border-spacing: 0;
					}
					& td
					{
						text-align: center;
						border-right: solid 1px var(--default-border);

						&:last-child
						{
							border-right: 0;
						}
					}
					&__header td
					{
						border-bottom: solid 1px var(--default-border);
					}
					&__title
					{
						font-size: 90%;
						width: 100%;
						text-align: center;
						border: solid 1px var(--default-border);
					}
				}
			}
		}
	}
}