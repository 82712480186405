.homestats
{
  position: relative;
  display: flex;
  justify-content: center;
  color: var(--default-color);
  margin-top: 10px;
  flex-wrap: wrap;
  
  &__meeting
  {
    max-width: 300px;
    min-width: 250px;
    margin-left: 2px;
    margin-right: 2px;
    flex: 1 1 auto;  
  }
}