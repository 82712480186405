.racingrace
{
	position: relative;
	display: flex;
	justify-content: center;
	color: var(--default-color);
	margin-top: 10px;
	flex-wrap: wrap;
	min-width: 250px;
	margin-left: 2px;
	margin-right: 2px;

    &__title
    {
      text-align: center;
      border: solid 1px var(--default-border);
	  width: 100%;
    }

	&__table
	{
		display: table;
		width: 100%;
		border-right: solid 1px var(--default-border);
		border-left: solid 1px var(--default-border);
		border-bottom: solid 1px var(--default-border);
		font-size: 80%;
		border-spacing: 0;

		&__row
		{
			&__header td
			{
				border-bottom: solid 1px var(--default-border);
			}
		}
		&__item
		{
			text-align: center;
			border-right: solid 1px var(--default-border);
			padding: 2px 5px;
			&:last-child
			{
				border-right: 0;
			}

			&__flag
			{
				width: 60px;
			}

			a
			{
				color: white;
			}
		}
	}
}