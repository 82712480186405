.header
{
    position: relative;
    display: flex;
    justify-content: center;
    color: var(--default-color);
    margin-top: 10px;

    &__logo
    {
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;

        @media screen and (max-width: 900px)
        {
            width: 28px;
            overflow-x: hidden;
            ;
        }

        &__img
        {
            height: 30px;
        }

        &__text
        {
            font-size: 26px;
            line-height: 32px;

            @media screen and (max-width: 1200px)
            {
                display: none;
            }
        }
    }

    &__nextrace
    {
        font-size: 20px;
        line-height: 32px;
        vertical-align: bottom;
        white-space: nowrap;
        overflow-x: hidden;
        max-width: calc(100vw - 100px);
    }

    &__menu
    {
        position: absolute;
		right: 5px;
		top: 5px;
		background-color: var(--color-button-back);
		border: 1px solid var(--default-border);
		border-radius: 5px;
		padding: 5px 5px;
        width: 20px;

		cursor: pointer;
		&:hover
		{
			background-color: var(--color-button-hover);
		}

        & span
        {
            display: block;
            width: 100%;
            height: 2px;
            background: var(--color-white);
            transition: all 0.3s;
            position: relative;
            border-radius: 2px;
            opacity: 0.8;
        }

        span + span
        {
            margin-top: 4.6px;
        }

        &__back
        {
            background-color: rgba(0, 0, 0, 0.3);
            display: none;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;
        
            &__content
            {
                flex-direction: column;
                position: absolute;
                display: flex;
                right: 5px;
                top: 15px;
                width: 180px;
                padding: 6px 0px;
                background-color: var(--color-button-back);
                border-radius: 5px;

                &__item
                {
                    width: 100%;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    color: white;
                    text-decoration: none;
                    &:hover
                    {
                        background-color: var(--color-button-hover);
                    }
                };
            }
        }
        &__active
        {
            display: block;
        }
    }
}