@import '../constants';

.trackers
{
	position: relative;
	max-width: 1300px;
	margin: 0 auto;
	&__maincont
	{
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		margin-top: 60px;
	}
	&__servers
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex: 1 1 auto;
		margin-bottom: 40px;

		&__server
		{
			width: 250px;
			margin-bottom: 20px;
			margin-right: 5px;
			border: 1px solid var(--default-border);
			
			&__title
			{
				text-align: center;
				background-color: var(--color-gold);
				margin-bottom: 5px;
				padding: 2px 5px;
			}
			&__details
			{
				padding: 2px;
				&__firmware
				{
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					&__name
					{
						width: 120px;
					}
				}
			}
		}
	}

	&__firmwares
	{
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		margin-bottom: 40px;

		&__title
		{
			width: 300px;
			text-align: center;
			background-color: var(--color-gold);
			margin-bottom: 5px;
			padding: 2px 5px;
		}

		&__firmware
		{
			&__details
			{
				padding: 2px;
			}
		}
	}
}